import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';

import PublicLayout from '../layout/public-layout';
import EmailVerificationConfirmation from '../components/user/auth/email-verification-confirmation';
import { FORGOT_PASSWORD_URL, PASSWORD_RESET_LINK_EXPIRED } from '../constants';
import SEO from '../components/seo';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function PasswordResetExpiration() {
  const handleForgetPassword = useCallback(async () => {
    navigate(FORGOT_PASSWORD_URL);
  }, []);

  return (
    <div>
      <SEO title="Sign In" />;
      <PublicLayout>
        <Container>
          <EmailVerificationConfirmation
            onButtonClick={handleForgetPassword}
            headingText={PASSWORD_RESET_LINK_EXPIRED.DISPLAY_MESSAGE}
            buttonText={PASSWORD_RESET_LINK_EXPIRED.BUTTON_TEXT}
          />
        </Container>
      </PublicLayout>
    </div>
  );
}
